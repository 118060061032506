import React, { useState, useRef } from 'react';
import axios from 'axios'

import { formatPrice } from '../../helpers/currency'

export default ( props ) => {

	const form = useRef();
	const { redeemedGiftCards, setRedeemedGiftCards, setGiftCardError, setGiftCardMessage, cartTotal, cart } =  props
	const [ voucher, setVoucher ] = useState( "" )

	const containsMakeoverDay = cart.find( product => product.__typename === "MakeoverDays" )

	const handleSubmit = async ( event ) => {
		// setProcessing( true );
		event.preventDefault();

		if ( redeemedGiftCards && redeemedGiftCards.find( redeemedVoucher => redeemedVoucher.code === voucher ) ) {
			return setGiftCardError( 'This gift card has already been redeemed.' )
		}
		const data = { code: voucher }
		const { data: payload } = await axios.post( '/api/gift-vouchers/redeem', data );

		if ( payload.status === 200 ) {
			// if this gift card has no monies
			if ( !payload.amountOnCard ) {
				return setGiftCardError( 'This gift card has already been redeemed.' )
			}
			setRedeemedGiftCards( state => (
				[ ...state , { code: voucher, amountOnCard: payload.amountOnCard } ]
			))
			
			let message;

			if ( payload.isMakeoverVoucher && !payload.isDiamondVoucher && containsMakeoverDay ) {
				message = { moneyUsed: "We hope you enjoy your Makeover Day." }
			} else if ( payload.isMakeoverVoucher && payload.isDiamondVoucher && containsMakeoverDay ) {
				message = { moneyUsed: "As part of your Diamond Makeover Day, you will have £75 to spend in the shop on the day (or £50 online)." }
			} else {

				let moneyUsed = cartTotal >= payload.amountOnCard ?
				`${ formatPrice( payload.amountOnCard ) } of your ${ formatPrice( payload.amountOnCard ) } has been applied.`
				: `${ formatPrice( payload.amountOnCard - ( payload.amountOnCard - cartTotal ) ) } of your ${ formatPrice( payload.amountOnCard ) } has been applied.`

				message = {
					moneyUsed: moneyUsed,
					moneyLeft: cartTotal >= payload.amountOnCard ? null : `You will have ${ formatPrice( payload.amountOnCard - cartTotal ) } remaining on this voucher.`
				}
			}

			setGiftCardMessage( state => (
				[ ...state, message ]
			) )

			setVoucher( "" )
			return
		}

		setGiftCardError( "Please enter a valid voucher" )
	}

	return (
		<div className="checkout-address">
			<form className="form" ref={ form } onSubmit={ e => handleSubmit(e) }>
				<div className="form__input-container">
					<label className="form__label"  htmlFor="text">Gift Voucher</label>
					<input
						className="form__input"
						type="text"
						name="Gift Voucher"
						id="Gift Voucher"
						value={ voucher }
						placeholder="Enter gift voucher code"
						onChange={e =>{ setVoucher( e.target.value ) }}
						/>
				</div>

				<div className="form__button-container">
					<button className="button button--primary button--full-width" type="submit">Apply gift voucher</button>
				</div>
			</form>
		</div>
	)
}
