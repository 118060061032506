import React, { useContext } from 'react'
import moment from 'moment'
import Img from "gatsby-image"
import { Link } from 'gatsby'

import { CartContext } from '../../context/CartContext';
import { formatPrice } from '../../helpers/currency';

import MakeoverDayIllustration from '../../images/svgs/makeover-day.svg'
import SpecialGiftIllutration from '../../images/svgs/special-message.svg'

export default function CheckoutProductList() {

	const { cart } = useContext( CartContext );

	return (
		<>
			{ cart.map( ( product, i ) => (
				<React.Fragment key={ i }>
					{ product.__typename === "MakeoverDays" ? (
						<Link to="/makeover-days" className="checkout-product">
							<MakeoverDayIllustration className="checkout-product__image checkout-product__image--illustration" />
							<div className="checkout-product__info">
								<div className="checkout-product__main-info">
									<div className="checkout-product__name">Makeover Day</div>
									<div className="checkout-product__price">{ formatPrice( product.price ) }</div>
								</div>
		
								<div className="checkout-product__detail">{ moment( product.Date ).format("Do MMM YYYY") }</div>
								<div className="checkout-product__detail">
									Quantity: { product.quantity }
								</div>
		
							</div>
						</Link>
					) : (
						product.giftCard ? (
							<Link to={`/products/${ product.slug }`} className="checkout-product">
								<SpecialGiftIllutration className="checkout-product__image checkout-product__image--illustration" />
								<div className="checkout-product__info">
									<div className="checkout-product__main-info">
										<div className="checkout-product__name">Gift Voucher</div>
										<div className="checkout-product__price">{ formatPrice( product.value ) }</div>
									</div>
									<div className="checkout-product__detail">
										Quantity: { product.quantity }
									</div>
			
								</div>
							</Link>
						) : (
							product.quantity.map( variation => (
								<Link to={`/products/${ product.slug }`} className="checkout-product" key={ `${ product.id } ${ variation.variationId}` }>
									<Img className="checkout-product__image" fluid={ product.images[0].imageFile.childImageSharp.fluid } />
									<div className="checkout-product__info">
										<div className="checkout-product__main-info">
											<div className="checkout-product__name">{ product.name }</div>
											<div className="checkout-product__price-container" >
												{ product.salePrice && 
													<div className="checkout-product__price checkout-product__price--sale">{ formatPrice( product.salePrice ) }</div>
												}
												<div className={`checkout-product__price ${ product.salePrice ? "disabled" : ''}`}>{ formatPrice( product.price ) }</div>
											</div>
											
										</div>
				
										{ product.brand && <div className="checkout-product__detail">{ product.brand.name }</div> }
										{ variation && variation.size && variation.size.size && <div className="checkout-product__detail">Size: { variation.size.size }</div> }
										<div className="checkout-product__detail">
											Quantity: { variation.quantity }
										</div>
				
									</div>
								</Link>
						)	)
					))}
				</React.Fragment>
			) ) }
		</>
	)
}