import React, { useState, useEffect, useRef } from 'react'

import useAuth from '../../hooks/useAuth'

export default function BillingDetails( props ) {

	const { deliveryDetails, billingDetails, setBillingDetails } = props;

	const form = useRef();
	const { state } = useAuth()

	const [ checked, toggleChecked ] = useState( true )
	const [ firstName, setFirstName ] = useState( state.user.firstName || '' )
	const [ lastName, setLastName ] = useState( state.user.lastName || '' )
	const [ addressLine1, setAddressLine1 ] = useState( state.user.addressLine1 || '' )
	const [ addressLine2, setAddressLine2 ] = useState( state.user.addressLine2 || '' )
	const [ city, setCity ] = useState( state.user.city || '' )
	const [ postcode, setPostcode ] = useState( state.user.postcode || '' )
	const [ county, setCounty ] = useState( state.user.county || '' )

	useEffect( () => {
		if ( !deliveryDetails ) {
			return;
		}

		if ( checked ) {
			setBillingDetails({
				billingName: deliveryDetails.shippingName,
				billingFirstName: deliveryDetails.shippingFirstName,
				billingLastName: deliveryDetails.shippingLastName,
				billingAddressLine1: deliveryDetails.shippingAddressLine1,
				billingAddressLine2: deliveryDetails.shippingAddressLine2, 
				billingCity: deliveryDetails.shippingCity,
				billingPostcode: deliveryDetails.shippingPostcode,
				billingCounty: deliveryDetails.shippingCounty,
			})
		}
	}, [ deliveryDetails, setBillingDetails, checked ] )

	useEffect(()=>{
		setBillingDetails({
			billingName: firstName + " " + lastName,
			billingFirstName: firstName,
			billingLastName: lastName,
			billingAddressLine1: addressLine1,
			billingAddressLine2: addressLine2, 
			billingCity: city,
			billingPostcode: postcode,
			billingCounty: county,
		})
	}, [ firstName, lastName, addressLine1, addressLine2, city, postcode, county, setBillingDetails ] )


	const setDetailsToDelivery = () => {
		const isChecked = !checked; // due to state update delay
		toggleChecked( !checked );

		if ( isChecked ) {
			setBillingDetails({
				billingName: deliveryDetails.shippingName,
				billingFirstName: deliveryDetails.shippingFirstName,
				billingLastName: deliveryDetails.shippingLastName,
				billingAddressLine1: deliveryDetails.shippingAddressLine1,
				billingAddressLine2: deliveryDetails.shippingAddressLine2, 
				billingCity: deliveryDetails.shippingCity,
				billingPostcode: deliveryDetails.shippingPostcode,
				billingCounty: deliveryDetails.shippingCounty,
			})
			

			setFirstName( state.user.firstName );
			setLastName( state.user.lastName );
			setAddressLine1( deliveryDetails.shippingAddressLine1 );
			setAddressLine2( deliveryDetails.shippingAddressLine2 );
			setCity( deliveryDetails.shippingCity );
			setPostcode( deliveryDetails.shippingPostcode );
			setCounty( deliveryDetails.shippingCounty );
		}
	}

	const handleFormSubmit = ( event ) => {
		event.preventDefault()
		form.current.reportValidity()
	}

	return (
		<>
			{ billingDetails && !checked &&
				<form className="form" ref={ form }>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="firstName">First Name</label>
						<input
							className="form__input"
							type="text" name="firstName" id="firstName" value={ firstName } placeholder="First name"
							onChange={ e => { setFirstName( e.target.value ) } }
							onBlur={ e => { handleFormSubmit( e ) }}
							required />
					</div>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="lastName">Last Name</label>
						<input
							className="form__input"
							type="text" name="lastName" id="lastName" value={ lastName } placeholder="Last name"
							onChange={ e => { setLastName( e.target.value ) } }
							onBlur={ e => { handleFormSubmit( e ) }}
							required />
					</div>

					<div className="form__input-container">
						<label className="form__label"  htmlFor="addressLine1">House No/Name</label>
						<input
							className="form__input"
							type="text" name="addressLine1" id="addressLine1" value={ addressLine1 } placeholder="House number/name"
							onChange={ e => { setAddressLine1( e.target.value ) } }
							onBlur={ e => { handleFormSubmit( e ) }}
							required />
					</div>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="addressLine2">Address 2</label>
						<input
							className="form__input"
							type="text" name="addressLine2" id="addressLine2" value={ addressLine2 } placeholder="Address Line 2"
							onChange={ e => { setAddressLine2( e.target.value ) } }/>
					</div>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="city">City</label>
						<input
							className="form__input"
							type="text" name="city" id="city" value={ city } placeholder="City"
							onChange={ e => { setCity( e.target.value ) } }
							onBlur={ e => { handleFormSubmit( e ) }}
							required />
					</div>
					<div className="form__input-container">
						<label className="form__label"  htmlFor="postcode">Postcode</label>
						<input
							className="form__input"
							type="text" name="postcode" id="postcode" value={ postcode } placeholder="Postcode"
							onChange={ e => { setPostcode( e.target.value ) } }
							onBlur={ e => { handleFormSubmit( e ) }}
							required />
					</div>

					<div className="form__input-container">
						<label className="form__label"  htmlFor="county">County</label>
						<input
							className="form__input"
							type="text" name="county" id="county" value={ county } placeholder="County"
							onChange={ e => { setCounty( e.target.value ) } }
							onBlur={ e => { handleFormSubmit( e ) }}
							required/>
					</div>
				</form>
			}

			<div className="form__input-container">
				<label className="form__checkbox-label" htmlFor="billingAddress">
					<input
						className="form__checkbox-input"
						type="checkbox"
						id="billingAddress"
						defaultChecked={ checked }
						onChange={ () => { setDetailsToDelivery() } }/>
					<div className="form__checkbox-checkmark"></div>
					<span className="form__checkbox-text">Use delivery address as billing address</span>
				</label>
			</div>
		</>
	)
}