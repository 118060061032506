import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios'

import { navigate } from "gatsby"

import { FaSpinner } from 'react-icons/fa'

import useAuth from '../../hooks/useAuth'

import { CartContext } from '../../context/CartContext'

export default function StripePayment( props ) {

	const { billingDetails, deliveryDetails, emailDetails, setProcessing, guest, setProductError, totalPrice, setInternalError } = props;

	const { cart, clearCart, postage, redeemedGiftCards } = useContext( CartContext );

	const [ token, setToken ] = useState( false );

	const [ loading, setLoading ] = useState( false );
	const { state } = useAuth()
	const { user } = state 

	const { shippingName, shippingAddressLine1, shippingCounty, shippingPostcode } = deliveryDetails

	const valid = () => {
		if ( !shippingName || !shippingAddressLine1 || !shippingCounty || !shippingPostcode ) {
			return false
		}
		
		return true
	}

	const handleSubmit = async ( event ) => {
		setProcessing( true );
		event.preventDefault();

		// The payment has been processed!
		const data = {
			...deliveryDetails,
			...billingDetails,
			...emailDetails,
			paymentIntent: { id: "Gift Card"},
			cart,
			postageId: postage.id,
			user,
			guestCheckout: guest,
			redeemedGiftCards
		}

		let payload;
		if ( guest ){
			payload = await axios.post( '/api/order/guest/create/giftVoucher', data );
		} else {
			payload = await axios.post( '/api/order/create/giftVoucher', data );
		}
		payload = payload.data

		if( payload.status === 500 ) {
			setInternalError( { message: payload.message, link: true } )
			setProcessing( false )
			window.scrollTo( 0, 0 )
			return
		}
		navigate( '/checkout/success' );
		clearCart();
		setProcessing( false );
	}
	
	useEffect(() => {

		const loadToken = async () => {
			setLoading( true );

			const data = {
				cart: cart,
				postageId: postage.id,
				redeemedGiftCards,
				totalPrice
			}

			let payload;

			if ( guest ) {
				payload = await axios.post( '/api/orders/guest/payment/giftVoucher', data )
				payload = payload.data
			} else {
				payload = await axios.post( '/api/orders/payment/giftVoucher', data )
				payload = payload.data
			}

			if ( payload.status === 402 ) {
				const errorString = `${payload.product} - ${payload.productVariation}`
				setProductError( errorString )
				return;
			} else if ( payload.status === 418 ) {
				const errorString = payload.message
				setInternalError( { message: errorString, link: false} )
				window.scrollTo( 0, 0 )
				return;
			} else if ( payload.status === 401 ) {
				const errorString = "There seems to be a problem finding your details, please can you log out and try again."
				setInternalError( { message: errorString, link: false } )
				window.scrollTo( 0, 0 )
			}

			setLoading( false )

			if ( !payload.total ) {
				setToken( true )
			}
		}

		if ( postage.id || postage.free ) {
			loadToken();
		}
		
	}, [ cart, postage, guest, setProductError, redeemedGiftCards, setInternalError, totalPrice ] )

	return (
		<div className="stripe">
			{ loading ? (
				<div className="product-grid__loading-container">
					<h3 className="product-grid__loading-text">Loading</h3>
					<FaSpinner className="loader" />
				</div>
			) : (
				<>
					<form className="form" onSubmit={ handleSubmit }>
						<button className="button button--primary" type="submit" disabled={ !token || !valid() }>
							Buy
						</button>
					</form>
				</>
			) }
		</div>
	)
}