import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'gatsby'

import { CartContext } from '../../context/CartContext'

import CheckoutProductList from './CheckoutProductList'
import LoginOrGuest from './LoginOrGuest'
import Checkout from './Checkout';

import useAuth from "../../hooks/useAuth"

import { formatPrice } from '../../helpers/currency';
import { cartTotal, cartSubTotal, calculateTotalDiscount, cartDiscount, cardHolderDiscount } from '../../helpers/cart';

import StaffDiscount from '../../images/svgs/staff-discount.svg'
import PurpleCardHolder from '../../images/svgs/purple-card-holder.svg'

import { FaSpinner } from 'react-icons/fa'

export default () => {

	const { cart, postage, redeemedGiftCards, setRedeemedGiftCards  } = useContext( CartContext )
	const { isAuthenticated, state, loadingUser } = useAuth()

	const [ processing, setProcessing ] = useState()
	const [ guestCheckout, setGuestCheckout ] = useState()
	const [ productsOnSale, setProductOnSale ] = useState()

	const totalDiscount = calculateTotalDiscount( cart, postage, redeemedGiftCards, state.user.purpleCardDiscount, state.user.staffDiscount )
	const voucherRedeemed = cartDiscount( redeemedGiftCards )
	const memberDiscount = cardHolderDiscount( cart, state.user.purpleCardDiscount, state.user.staffDiscount )

	useEffect(() => {
		const cartHasSaleItem = cart.find( product => product.salePrice )

		if ( cartHasSaleItem ) {
			setProductOnSale( true )
		} else {
			setProductOnSale( false )
		}

	}, [ cart ] )

	useEffect(() => {
		setRedeemedGiftCards([])
	}, [ setRedeemedGiftCards ])

	return (
		<div className="checkout">

		{ loadingUser ? (
			<>
				<div className="checkout__loading-container checkout__loading-container--loading-user">
					<FaSpinner className="loader" />
					<h3 className="checkout__loading-text">Loading</h3>
				</div>
			</>
		) : (
			<>
				{ cart && cart.length > 0 ? (
				<>
					{ isAuthenticated ? (
						<Checkout setProcessing={ setProcessing } />
					) : (
						<>
							{ guestCheckout &&
								<Checkout setProcessing={ setProcessing } guest={ true } />
							}
							{ !guestCheckout &&
								<LoginOrGuest setGuestCheckout={ setGuestCheckout } />
							}
						</>
					) }

					<div className="checkout__price-container u-hide-phablet">
						<h2 className="checkout__price-container-header">Order Summary</h2>
						<CheckoutProductList />
						<div className="checkout__totals-container">
							<div className="checkout__totals">Subtotal <span className="checkout__totals-price">{ formatPrice( cartSubTotal( cart ) ) }</span></div>
							{ !!memberDiscount &&
								<div className="checkout__totals">
									Discount { productsOnSale ? (
										'*'
									) : (
										<>
											({ state.user.staffDiscount ? '50%' : '10%' })
										</>
									) }

									<span className="checkout__totals-price">-{ formatPrice( memberDiscount ) }</span>
								</div>
							}
							<div className="checkout__totals">Delivery <span className="checkout__totals-price">{ postage.price ? formatPrice( postage.price ) : '-' }</span></div>
							{ !!voucherRedeemed &&
								<div className="checkout__totals">
									Vouchers
									<span className="checkout__totals-price">-{ cartTotal( cart, postage, memberDiscount ) <= voucherRedeemed ? formatPrice( cartTotal( cart, postage, memberDiscount ) ) : formatPrice( voucherRedeemed ) }</span>
								</div>
							}
							<div className="checkout__totals checkout__totals--total">Total <span className="checkout__totals-price">{ formatPrice( cartTotal( cart, postage, totalDiscount ) ) }</span></div>
						</div>

						{ !!memberDiscount &&
							<>
								{ productsOnSale && state.user.staffDiscount &&
									<div className="checkout__extra-info">
										* 25% discount applied on sale items. 50% discount on full price items.
									</div>
								}

								{ productsOnSale && state.user.purpleCardDiscount &&
									<div className="checkout__extra-info">
										* 10% applied on full price items.
									</div>
								}
							</>
						}

						{ !!state.user.staffDiscount &&
							<StaffDiscount className="checkout__member-image" />
						}

						{ !!state.user.purpleCardDiscount &&
							<PurpleCardHolder className="checkout__member-image" />
						}
					</div>

				</>
			) : (
				<div className="cart">
					<div className="cart__header cart__header--empty-bag">
						<h1 className="cart__heading">Your shopping bag is empty</h1>
						<span className="cart__header-description">Please feel free to have a browse and add items to your shopping bag.</span>
						<Link to="/clothing" className="cart__header-button button button--primary">
							Back to Shopping
						</Link>
					</div>
				</div>
			) }
			</>
		) }

		{ processing &&
			<div className="checkout__loading-container">
				<div className="checkout__loader">
					<FaSpinner className="loader" />
					<h3 className="checkout__loading-text">Processing Payment</h3>
				</div>
			</div>
		}

		</div>
	)
}