import React, { useState, useEffect, useRef } from 'react';

import useAuth from '../../hooks/useAuth'

export default ( props ) => {

	const form = useRef();
	const { guest, setEmailDetails } =  props
	const { state } = useAuth()

	const [ email, setEmail ] = useState( state.user.email || '' );

	useEffect(()=>{
		setEmailDetails({
			contactEmail: email
		})
	}, [ email, setEmailDetails ])

	return (
		<div className="checkout-address">
			<form className="form" ref={ form }>
				<div className="form__input-container">
					<label className="form__label"  htmlFor="email">Email</label>
					<input
						className="form__input"
						type="text"
						name="email"
						id="email"
						value={ email }
						placeholder="Enter email address"
						onChange={ e => { setEmail( e.target.value ) } }
						disabled={ !guest ? 'disabled' : '' }
						required />
				</div>
			</form>
		</div>
	)
}
