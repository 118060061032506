import React, { useState, useEffect, useContext } from 'react'
import { Link } from "gatsby"

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { CartContext } from '../../context/CartContext';

import StripePayment from './StripePayment'
import CheckoutDetails from './CheckoutDetails'
import CheckoutEmail from './CheckoutEmail'
import PaymentMethod from './PaymentMethod'
import CheckoutProductList from './CheckoutProductList'
import BillingDetails from './BillingDetails'
import PaypalButton from './PaypalButton'
import GiftPayment from './GiftPayment'

import useAuth from "../../hooks/useAuth"

import { formatPrice } from '../../helpers/currency';
import { cartTotal, cartSubTotal, calculateTotalDiscount } from '../../helpers/cart';

import Shipping from "../Shipping/Shipping"

import { RiArrowRightSLine } from 'react-icons/ri'
import Chevron from '../../images/svgs/chevron.svg';
import GiftVoucher from './GiftVoucher';

const stripe = loadStripe( process.env.STRIPE_PK )

export default function Checkout ( props ) {

	const { cart, postage, redeemedGiftCards, setRedeemedGiftCards } = useContext( CartContext );

	const { setProcessing, guest } = props
	const { state } = useAuth()

	const [ deliveryDetails, setDeviveryDetails ] = useState()
	const [ emailDetails, setEmailDetails ] = useState()
	const [ billingDetails, setBillingDetails ] = useState()
	const [ paymentMethod, setPaymentMethod ] = useState()
	const [ productError, setProductError ] = useState()
	const [ validDeliveryDetails, setValidDeliveryDetails ] = useState()
	const [ validBillingDetails, setValidBillingDetails ] = useState()
	const [ giftCardError , setGiftCardError ] = useState()
	const [ giftCardMessage, setGiftCardMessage ] = useState( [] )
	const [ internalError , setInternalError ] = useState()

	const totalDiscount = calculateTotalDiscount( cart, postage, redeemedGiftCards, state.user.purpleCardDiscount, state.user.staffDiscount )
	const totalPrice = cartTotal( cart, postage, totalDiscount )

	const validPostage = () => { 
		return ( postage.price || postage.free )
	}

	useEffect(() => {

		const checkDeliveryDetails = () => {
			if ( !deliveryDetails || !deliveryDetails.shippingName || !deliveryDetails.shippingAddressLine1 || !deliveryDetails.shippingCounty ||
					!deliveryDetails.shippingPostcode || !emailDetails.contactEmail ) {
				return false
			}
			
			return true
		}
	
		const checkBillingDetails = () => {
			if ( !billingDetails || !billingDetails.billingName || !billingDetails.billingAddressLine1 || !billingDetails.billingCounty ||
					!billingDetails.billingPostcode || !emailDetails.contactEmail ) {
				return false
			}
			
			return true
		}

		if ( checkBillingDetails() ) {
			setValidBillingDetails( true )
		} else {
			setValidBillingDetails( false )
		}

		if ( checkDeliveryDetails() ) {
			setValidDeliveryDetails( true )
		} else {
			setValidDeliveryDetails( false )
		}

	}, [ deliveryDetails, billingDetails, emailDetails ])

	return (
		<div className="checkout__details-container">

			<h2 className="checkout__header">Checkout</h2>

			{ productError &&
				<div className="checkout__error-container">
					<p className="checkout__error-message">Error - Unfortunately 1 or more of the products in your <Link to="/cart">bag</Link> are no longer in stock. Apologies for any inconvenience.</p>
					<span className="checkout__error-product">
						<RiArrowRightSLine className="checkout__error-icon" />
						{ productError }
					</span>
				</div>
			}

			{ internalError &&
				<div className="checkout__error-container">
					<span className="checkout__error-message">
						{ internalError.message }
					</span>
					{ internalError.link && 
						<Link
						to="/contact-us"
						className="checkout__error-message">
							Contact Us 
						</Link>
					}
				</div>
			}

			<div className="checkout__section u-show-phablet">
				<h3 className="checkout__section-header">Items</h3>
				<div className="checkout__section-container">
					<CheckoutProductList />
				</div>
			</div>

			<div className="checkout__section">
				<h3 className="checkout__section-header">
					Email address
					<Chevron className="checkout__section-header-icon" />
				</h3>
				<div className="checkout__section-container">
					<CheckoutEmail setEmailDetails={ setEmailDetails } guest={ guest } />
				</div>
			</div>

			<div className="checkout__section">
				<h3 className="checkout__section-header">
					Delivery Details
					<Chevron className="checkout__section-header-icon" />
				</h3>
				<div className="checkout__section-container">
					<CheckoutDetails setDeviveryDetails={ setDeviveryDetails } guest={ guest } />
				</div>
			</div>

			<div className="checkout__section">
				<h3 className="checkout__section-header">
					Billing Details
					<Chevron className="checkout__section-header-icon" />
				</h3>
				<div className="checkout__section-container">
					<BillingDetails deliveryDetails={ deliveryDetails } billingDetails={ billingDetails } setBillingDetails={ setBillingDetails } />
				</div>
			</div>

			<div className="checkout__section">
				<h3 className="checkout__section-header">
					Postage Options
					<Chevron className="checkout__section-header-icon" />
				</h3>
				<div className="checkout__section-container">
					<Shipping />
				</div>
			</div>

			<div className="checkout__section u-show-phablet">
				<h3 className="checkout__section-header">Total</h3>
				<div className="checkout__section-container">
				<div className="checkout__totals-container">
					<div className="checkout__totals">Subtotal: <span className="checkout__totals-price">{ formatPrice( cartSubTotal( cart ) ) }</span></div>
					<div className="checkout__totals">Delivery: <span className="checkout__totals-price">{ postage && postage.price ? formatPrice( postage.price ) : '-' }</span></div>
					{ !!totalDiscount &&
						<div className="checkout__totals">
							Discount ({ state.user.staffDiscount ? '50%' : '10%' })
							<span className="checkout__totals-price">-{ formatPrice( totalDiscount ) }</span>
						</div>
					}
					<div className="checkout__totals checkout__totals--total">Total to pay: <span className="checkout__totals-price">{ formatPrice( totalPrice ) }</span></div>
				</div>
				</div>
			</div>

			<div className="checkout__section">
				<h3 className="checkout__section-header">
					Gift Voucher
					<Chevron className="checkout__section-header-icon" />
				</h3>
				<div className="checkout__section-container">
					{ validDeliveryDetails && validBillingDetails && validPostage() &&
						<GiftVoucher setRedeemedGiftCards={ setRedeemedGiftCards } redeemedGiftCards={ redeemedGiftCards } setGiftCardError={ setGiftCardError } setGiftCardMessage={ setGiftCardMessage } cartTotal={ cartTotal( cart, postage, totalDiscount )} cart={ cart } />
					}
				</div>
			</div>

			{ giftCardError &&
				<div className="checkout__error-container">
					<span className="checkout__error-message">
						{ giftCardError }
					</span>
				</div>
			}

			{ giftCardMessage.map( ( message, i ) => (
				<div className="checkout__error-container" key={ i }>
					<span className="checkout__error-message">
						{ message.moneyUsed }
					</span>
					{ message.moneyLeft &&
						<span className="checkout__error-message">
							{ message.moneyLeft }
						</span>
					}
				</div>
			) ) }

			<div className="checkout__section checkout__section--payment-method">
				<h3 className="checkout__section-header">
					Payment Method
					<Chevron className="checkout__section-header-icon" />
				</h3>
				<div className="checkout__section-container">
					{ validDeliveryDetails && validBillingDetails && validPostage() &&
						<PaymentMethod setPaymentMethod={ setPaymentMethod } giftCardPayment={ !totalPrice }/>
					}
				</div>
			</div>

			<div className="checkout__section">
				<h3 className="checkout__section-header">
					Payment
					<Chevron className="checkout__section-header-icon" />
				</h3>
				<div className="checkout__section-container">
					{ deliveryDetails && paymentMethod && validPostage() && !productError && !!totalPrice && !internalError &&
						<>
							{ paymentMethod === 'card' &&
								<Elements stripe={ stripe }>
									<StripePayment deliveryDetails={ deliveryDetails } billingDetails={ billingDetails } emailDetails={ emailDetails } setProcessing={ setProcessing } setProductError={ setProductError } guest={ guest } totalPrice={ totalPrice } setInternalError={ setInternalError } />
								</Elements>
							}

							{ paymentMethod === 'paypal' &&
								<div>
									<PaypalButton deliveryDetails={ deliveryDetails } billingDetails={ billingDetails } emailDetails={ emailDetails } setProcessing={ setProcessing } setProductError={ setProductError } guest={ guest } totalPrice={ totalPrice } setInternalError={ setInternalError }/>
								</div>
							}
						</>
					}
					{ deliveryDetails && paymentMethod && validPostage() && !productError && !totalPrice && !internalError &&
					<>
						{ paymentMethod === 'giftVoucher' &&
							<GiftPayment deliveryDetails={ deliveryDetails } billingDetails={ billingDetails } emailDetails={ emailDetails } setProcessing={ setProcessing } setProductError={ setProductError } guest={ guest } totalPrice={ totalPrice } setInternalError={ setInternalError }/>
						}
					</>
				}
				</div>
			</div>
		</div>
	)
}