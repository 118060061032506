import React from 'react'

import Accordion from '../../components/Accordion/Accordion'

import LoginForm from '../../components/LoginForm/LoginForm'
import RegisterForm from '../../components/RegisterForm/RegisterForm'

export default function LoginOrGuest( props ) {

	const { setGuestCheckout } = props

	return (
		<>
			<div className="checkout__details-container">

				<h2 className="checkout__header">Checkout Sign In</h2>

				<div className="checkout__accordion-container">
					<Accordion noBorder={ true } openOnLoad={ true }>
						<div accordionTitle="Returning Customer">
							<LoginForm />
						</div>
						<div accordionTitle="New Customer">
							<RegisterForm />
						</div>
						<div accordionTitle="Checkout as guest">
							<button className="button button--primary" onClick={ () => setGuestCheckout( true ) }>Proceed with checkout as guest</button>
						</div>
					</Accordion>
				</div>

			</div>
		</>
	)
}