import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios'

import { navigate } from "gatsby"

import {
	useStripe,
	useElements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement } from '@stripe/react-stripe-js';

import { FaSpinner } from 'react-icons/fa'

import useAuth from '../../hooks/useAuth'

import { CartContext } from '../../context/CartContext'
import { GTPurchase } from '../../helpers/gtagEvents'
import { cartTotal, calculateTotalDiscount } from '../../helpers/cart'

const STRIPE_ELEMENT_STYLES = {
	base: {
		color: "#000",
		fontFamily: "'OptimaLTPro-Roman', Helvetica Neue, sans-serif",
		"::placeholder": {
			color: "#828282"
		},
		fontSmoothing: "antialiased",
		fontSize: "16px"
	},
	invalid: {
		color: "#e92349"
	}
}

const CARD_NUMBER = {
	style: STRIPE_ELEMENT_STYLES,
	placeholder: 'Card Number'
}

const CVC = {
	style: STRIPE_ELEMENT_STYLES,
	placeholder: 'CVC'
}

const EXPIRY = {
	style: STRIPE_ELEMENT_STYLES,
	placeholder: 'Expiry Date MM/YY'
}

export default function StripePayment( props ) {
	const stripe = useStripe();
	const elements = useElements();
	const { billingDetails, deliveryDetails, emailDetails, setProcessing, guest, setProductError, totalPrice, setInternalError } = props;

	const { cart, clearCart, postage, redeemedGiftCards } = useContext( CartContext );

	const [ token, setToken ] = useState( null );
	const [ error, setError ] = useState( '' )

	const [ loading, setLoading ] = useState( false );
	const { state } = useAuth()
	const { user } = state 

	const { shippingName, shippingAddressLine1, shippingAddressLine2, shippingCity, shippingCounty, shippingPostcode } = deliveryDetails

	const valid = () => {
		if ( !shippingName || !shippingAddressLine1 || !shippingCounty || !shippingPostcode ) {
			return false
		}
		
		return true
	}

	const handleSubmit = async ( event ) => {
		setProcessing( true );
		event.preventDefault();

		const result = await stripe.confirmCardPayment( token, {
			payment_method: {
				card: elements.getElement( CardNumberElement ),
				billing_details: {
					name: shippingName,
					email: emailDetails.contactEmail,
					address: {
						city: shippingCity,
						line1: shippingAddressLine1,
						line2: shippingAddressLine2,
						postal_code: shippingPostcode,
						state: shippingCounty
					}
				}
			}
		} )

		if (result.error) {
			// Show error to your customer (e.g., insufficient funds)
			setError(result.error.message)
			setProcessing( false );
			console.log('result.error.message', result.error.message);
		} else {
			// The payment has been processed!
			if ( result.paymentIntent.status === 'succeeded' ) {
				const data = {
					...deliveryDetails,
					...billingDetails,
					...emailDetails,
					paymentIntent: result.paymentIntent,
					cart,
					postageId: postage.id,
					user,
					guestCheckout: guest,
					redeemedGiftCards
				}

				let payload;
				if ( guest ) {
					payload = await axios.post( '/api/order/guest/create/stripe', data );
				} else {
					payload = await axios.post( '/api/order/create/stripe', data );
				}
				payload = payload.data

				if ( payload.status === 500 ) {
					setInternalError( { message: payload.message, link: true } )
					setProcessing( false )
					window.scrollTo( 0, 0 )
					return
				}

				navigate( '/checkout/success' )

				const totalDiscount = calculateTotalDiscount( cart, postage, redeemedGiftCards, state.user.purpleCardDiscount, state.user.staffDiscount )

				const gtData = {
					transactionId: result.paymentIntent.id,
					value: parseFloat( cartTotal( cart, postage, totalDiscount ) ),
					postagePrice: postage.price ? parseFloat( postage.price ) : 0
				}

				GTPurchase( cart, gtData )
				clearCart();
				setProcessing( false );
			}
		}
	}
	
	useEffect(() => {
		const loadToken = async () => {
			setLoading( true );

			const data = {
				cart: cart,
				postageId: postage.id,
				redeemedGiftCards,
				totalPrice
			}

			let payload;

			if ( guest ) {
				payload = await axios.post( '/api/orders/guest/payment/stripe', data )
				payload = payload.data
			} else {
				payload = await axios.post( '/api/orders/payment/stripe', data )
				payload = payload.data
			}

			if ( payload.status === 402 ) {
				const errorString = `${payload.product} - Size: ${payload.productVariation.size}`
				setProductError( errorString )
				window.scrollTo( 0, 0 )
				return;
			} else if ( payload.status === 418 ) {
				const errorString = payload.message
				setInternalError( { message: errorString, link: false } )
				window.scrollTo( 0, 0 )
				return;
			} else if ( payload.status === 401 ) {
				const errorString = "There seems to be a problem finding your details, please can you log out and try again."
				setInternalError( { message: errorString, link: false } )
				window.scrollTo( 0, 0 )
			}

			setToken( payload.client_secret );
			setLoading( false );
		}

		if ( postage.id || postage.free ) {
			loadToken();
		}
		
	}, [ cart, postage, guest, setProductError, redeemedGiftCards ] )

	return (
		<div className="stripe">
			{ loading ? (
				<div className="product-grid__loading-container">
					<h3 className="product-grid__loading-text">Loading</h3>
					<FaSpinner className="loader" />
				</div>
			) : (
				<>
					<form className="form" onSubmit={ handleSubmit }>

						<div className="stripe__inputs-container">
							<div className="stripe__input stripe__input--card-number">
								<CardNumberElement
									className="stripe__element"
									id="card_number"
									options={ CARD_NUMBER } />
							</div>
							<div className="stripe__input stripe__input--expiry">
								<CardExpiryElement
									className="stripe__element"
									id="card_expiry"
									options={ EXPIRY } />
							</div>
							<div className="stripe__input stripe__input--cvc">
								<CardCvcElement
									className="stripe__element"
									id="card_cvc"
									options={ CVC } />
							</div>
						</div>
						{ ( error.length > 1 ) && (
							<div className="form__error-container">
								<span className="form__error-message"> { error } </span>
								<span className="form__error-message">Please check your details are correct or try another payment method.</span>
							</div>
						)}

						<button className="button button--primary" type="submit" disabled={ !stripe || !valid() }>
							Buy
						</button>
					</form>
				</>
			) }
		</div>
	)
}