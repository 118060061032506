import React from 'react'

export default function PaymentMethod( props ) {

	const { setPaymentMethod, giftCardPayment } = props

	return (
		<>
		{ giftCardPayment ? (
				<div className="payment-method">
					<ul className="payment-method__list">
						<li className="payment-method__item">
							<div className="form__input-container">
								<label className="form__checkbox-label" htmlFor="paymentGiftVoucher">
									<input
										className="form__checkbox-input"
										type="radio"
										name="paymentMethod"
										id="paymentGiftVoucher"
										value="giftVoucher"
										onChange={ (e) => setPaymentMethod( e.target.value ) } />
									<div className="form__checkbox-checkmark"></div>
									<span className="form__checkbox-text"> Gift Voucher </span>
								</label>
							</div>
						</li>
					</ul>
			</div>

		) : (
			<div className="payment-method">
				<ul className="payment-method__list">
					<li className="payment-method__item">
						<div className="form__input-container">
							<label className="form__checkbox-label" htmlFor="paymentPaypal">
								<input
									className="form__checkbox-input"
									type="radio"
									name="paymentMethod"
									id="paymentPaypal"
									value="paypal"
									onChange={ (e) => setPaymentMethod( e.target.value ) } />
								<div className="form__checkbox-checkmark"></div>
								<span className="form__checkbox-text">Paypal</span>
							</label>
						</div>
					</li>
					
					<li className="payment-method__item">
						<div className="form__input-container">
							<label className="form__checkbox-label" htmlFor="card">
								<input
									className="form__checkbox-input"
									type="radio"
									name="paymentMethod"
									id="card"
									value="card"
									onChange={ (e) => setPaymentMethod( e.target.value ) } />
								<div className="form__checkbox-checkmark"></div>
								<span className="form__checkbox-text">Card payment</span>
							</label>
						</div>
					</li>
				</ul>
			</div>
		)}
		</>
	)
}