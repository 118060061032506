import React from 'react'
import { Link } from 'gatsby'

export default function Success() {

	return (
		<div className= "wrapper">
			<div className="form-page">
				<div className="form-page__header">
					<h1 className="form-page__heading">Your order has been processed!</h1>
					<p className="form-page__info">
						Please fill out the following form if you have any queries or questions.
					</p>
					<p className="form-page__info">You can view you order history by going to the <Link to="/account">my account page</Link> and by clicking on <Link to="/account/orders">orders</Link>.</p>
					<p className="form-page__info">Please direct any questions you have to dream on by using the <Link to="/contact-us">contact page</Link>.</p>
					<p className="form-page__info">Thanks for shopping with us online!</p>
				</div>
			</div>
		</div>
	)
}
