import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import axios from 'axios'
import scriptLoader from "react-async-script-loader";
import {  navigate } from 'gatsby'

import useAuth from '../../hooks/useAuth'
import { CartContext } from '../../context/CartContext'
import { GTPurchase } from '../../helpers/gtagEvents'
import { cartTotal, calculateTotalDiscount } from '../../helpers/cart'

import { FaSpinner } from 'react-icons/fa'

const CLIENT_ID = process.env.PAYPAL_CLIENT_ID;

let PayPalButton = null;

export const PaypalButton = ( props ) => {

	const [ loading, setLoading ] = useState( true )
	const [ showButtons, setShowButtons ] = useState( false )
	const { state } = useAuth()
	const { user } = state 
	const { billingDetails, deliveryDetails, emailDetails, setProcessing, isScriptLoaded, isScriptLoadSucceed, guest, setProductError, totalPrice, setInternalError } = props
	const { cart, clearCart, postage, redeemedGiftCards } = useContext( CartContext );

	useEffect(() => {
		const loadToken = async () => {
			const data = {
				cart: cart,
				postageId: postage.id,
				...deliveryDetails,
				redeemedGiftCards,
				totalPrice
			}
	
			let payload;

			if ( guest ) {
				payload = await axios.post( '/api/orders/guest/payment/paypal', data )
				payload = payload.data
			} else {
				payload = await axios.post( '/api/orders/payment/paypal', data )
				payload = payload.data
			}

			if ( payload.status === 402 ) {
				const errorString = `${payload.product} - Size: ${payload.productVariation.size}`
				setProductError( errorString )
				window.scrollTo( 0, 0 )
				return;
			} else if ( payload.status === 418 ) {
				const errorString = payload.message
				setInternalError( { message: errorString, link: false } )
				window.scrollTo( 0, 0 )
				return;
			} else if ( payload.status === 401 ) {
				const errorString = "There seems to be a problem finding your details, please can you log out and try again."
				setInternalError( { message: errorString, link: false } )
				window.scrollTo( 0, 0 )
			}

			if ( isScriptLoaded && isScriptLoadSucceed ) {
				PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
				setShowButtons( true )
				setLoading ( false )
			}
	
			return payload
		}

		if ( postage.id || postage.free ) {
			loadToken();
		}

	},[ isScriptLoaded, isScriptLoadSucceed, cart, deliveryDetails, postage, guest, setProductError, redeemedGiftCards, setInternalError, totalPrice ])

	const loadToken = async () => {

		const data = {
			cart: cart,
			postageId: postage.id,
			...deliveryDetails,
			redeemedGiftCards,
			totalPrice
		}

		let payload;

		if ( guest ) {
			payload = await axios.post( '/api/orders/guest/payment/paypal', data )
			payload = payload.data
		} else {
			payload = await axios.post( '/api/orders/payment/paypal', data )
			payload = payload.data
		}

		if ( payload.status === 402 ) {
			const errorString = `${payload.product} - ${payload.productVariation}`
			setProductError( errorString )
			return;
		} else if ( payload.status === 418 ) {
			const errorString = payload.message
			setInternalError( { message: errorString, link: false } )
			window.scrollTo( 0, 0 )
			return;
		} else if ( payload.status === 401 ) {
			const errorString = "There seems to be a problem finding your details, please can you log out and try again."
			setInternalError( { message: errorString, link: false } )
			window.scrollTo( 0, 0 )
		}

		return payload
	}

	const createOrder = async (data, actions) => {
		const result = await loadToken()
		return actions.order.create({
			purchase_units: [
				result
			],
			payer: {
				name: {
					given_name: billingDetails.billingFirstName,
					surname: billingDetails.billingLastName
				  },
				email_address: emailDetails.contactEmail,
				address: {
					address_line_1: billingDetails.billingAddressLine1,
					address_line_2: billingDetails.billingAddressLine2,
					admin_area_2: billingDetails.billingCity,
					admin_area_1: billingDetails.billingcounty,
					postal_code: billingDetails.billingPostcode,
					country_code: "GB"
				}
			}
		});
	};

	const onApprove = (data, actions) => {
		setProcessing( true )

		actions.order.capture().then( async details => {

			if ( details.status === "COMPLETED" ) {
				// The payment has been processed!
				const orderData = {
					...deliveryDetails,
					...billingDetails,
					...emailDetails,
					paymentIntent: details,
					cart,
					postageId: postage.id,
					user,
					guestCheckout: guest,
					redeemedGiftCards
				}

				let payload;
				if ( guest ){
					payload = await axios.post( '/api/order/guest/create/paypal', orderData );
				} else{
					payload = await axios.post( '/api/order/create/paypal', orderData );
				}
				payload = payload.data

				if( payload.status === 500 ) {
					setInternalError( { messgae: payload.message, link: true } )
					setProcessing( false )
					setShowButtons ( false )
					window.scrollTo( 0, 0 )
					return
				}
		
				navigate( '/checkout/success' )

				const totalDiscount = calculateTotalDiscount( cart, postage, redeemedGiftCards, state.user.purpleCardDiscount, state.user.staffDiscount )

				const gtData = {
					transactionId: details.id,
					value: parseFloat( cartTotal( cart, postage, totalDiscount ) ),
					postagePrice: postage.price ? parseFloat( postage.price ) : 0
				}

				GTPurchase( cart, gtData )
				clearCart()
				setProcessing( false )
			}
			setShowButtons ( false )
		});
	};

	const onError = (err) => {
		// Show an error page here, when an error occurs
		console.log( err )
	}

	return (
		<>
			{ loading &&
				<div className="product-grid__loading-container">
					<h3 className="product-grid__loading-text">Loading</h3>
					<FaSpinner className="loader" />
				</div>
			}

			{ showButtons &&
				<PayPalButton
					createOrder={(data, actions) => createOrder(data, actions)}
					onApprove={(data, actions) => onApprove(data, actions)}
					onError = { (err) => onError( err )}
				/>
			}
		</>
	)
}

export default scriptLoader(`https://www.paypal.com/sdk/js?currency=GBP&client-id=${CLIENT_ID}&disable-funding=card,sofort`)(PaypalButton);