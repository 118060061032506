import React, { useContext, useEffect, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { CartContext } from '../../context/CartContext';

import { formatPrice } from '../../helpers/currency';
import { cartSubTotal, cardHolderDiscount } from '../../helpers/cart'

import useAuth from "../../hooks/useAuth"

export default function Shipping() {

	const data = useStaticQuery(graphql`
		query ShippingQuery {
			strapi {
				postage {
					freePostage
					freePostageOver
					clickAndCollect
					postage {
						id
						name
						price
					}
				}
			}
		}
	` )

	const { cart, setPostage } = useContext( CartContext );
	const shipping = data.strapi.postage
	const [ subTotal, setSubTotal ] = useState(0)
	const [ onlyGiftCards, setOnlyGiftCards ] = useState( true )
	const { state } = useAuth()

	useEffect(() => {
		let subTotal = cartSubTotal( cart )

		const cardDiscount = cardHolderDiscount( cart, state.user.purpleCardDiscount, state.user.staffDiscount )

		subTotal -= cardDiscount

		setSubTotal( subTotal )

		const onlyGift = cart.find( product => !product.giftCard )

		setOnlyGiftCards( !onlyGift )

		if ( subTotal >= shipping.freePostageOver || !onlyGift ) {
			setPostage({
				price: 0,
				free: true,
				id: 0
			})
		} else {
			setPostage( { price: 0 } )
		}

	}, [ cart, shipping, setPostage ])

	const handlePostage = event => {
		const value = event.target.value

		if ( value === 'freePostage' || value === 'clickAndCollect' ) {
			setPostage({
				price: 0,
				free: true,
				clickAndCollect: value === 'clickAndCollect',
				id: value === 'freePostage' ? 0 : -1
			})
		} else {
			const price = parseFloat( value )
			const id = parseFloat( event.target.id )

			setPostage( { price, id } )
		}
	}
	
	return(
		<>
			{ shipping.freePostage || subTotal >= shipping.freePostageOver || onlyGiftCards ? (
				<>
					<div className="form__input-container">
						<label className="form__checkbox-label" htmlFor="freePostage">
							<input
								className="form__checkbox-input"
								type="radio"
								name="shipping"
								id="freePostage"
								value="freePostage"
								defaultChecked={ true }
								onClick={ e => handlePostage(e) } />
							<div className="form__checkbox-checkmark"></div>
							<span className="form__checkbox-text">Free Postage</span>
						</label>
					</div>
				</>
			) : (
				<>
					{ shipping.postage.map( postage => (
						<div className="form__input-container" key={ postage.id }>
							<label className="form__checkbox-label" htmlFor={ postage.id }>
								<input
									className="form__checkbox-input"
									type="radio"
									name="shipping"
									id={ postage.id }
									value={ postage.price }
									onClick={ (e) => handlePostage(e) } />
								<div className="form__checkbox-checkmark"></div>
								<span className="form__checkbox-text">{ postage.name } - { formatPrice( postage.price ) }</span>
							</label>
						</div>
					) )	}
				</>
			) }

			{ shipping.clickAndCollect &&
				<div className="form__input-container">
					<label className="form__checkbox-label" htmlFor="clickAndCollect">
						<input
							className="form__checkbox-input"
							type="radio"
							name="shipping"
							id="clickAndCollect"
							value="clickAndCollect"
							onClick={ e => handlePostage(e) } />
						<div className="form__checkbox-checkmark"></div>
						<span className="form__checkbox-text">Click &amp; Collect</span>
					</label>
				</div>
			}
			<Link to="/delivery-and-returns" className="checkout__link">View delivery &amp; returns info</Link>
		</>
	)
}
