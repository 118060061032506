import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"

import SEO from "../components/SEO/Seo"
import CheckoutWrapper from "../components/Checkout/CheckoutWrapper"
import Success from '../components/Checkout/Success'

import { FaSpinner } from 'react-icons/fa'

export default ({ location }) => {

	const [ hasMounted, setHasMounted ] = useState(false)

	useEffect(() => {
		setHasMounted( true )
	}, [] )

	if ( !hasMounted ) {
		return (
			<div className="account-page__loading-container">
				<FaSpinner className="loader" />
				<h3 className="account-page__loading-text">Loading</h3>
			</div>
		)
	}

	return (
		<>
			<SEO title="Checkout" description="Checkout page to purchase products in your shopping bag on dream on." pathname={ location.pathname } />
			<div className="wrapper">
				<Router basepath="/checkout">
					<CheckoutWrapper default />
					<Success path="/success" />
				</Router>
			</div>
		</>
	)
}
